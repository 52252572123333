.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary);
    color: var(--primaryText);
}

#checkbox_toggle {
    display: none;
}

.hamburger {
    display: none;
    user-select: none;
}

.nav-links {
    width: 100%;
    margin: 0.25rem 0 0 0;
}

.menu {
    display: flex;
    gap: 1em;
}

.navbar__item {
    flex-basis: 15%;
    list-style-type: none;
    margin: 0.2rem 0 0 0;
}

.navbar__logo {
    flex-basis: 5%;
    list-style-type: none;
}

.navbar__link {
    background-color: inherit;
    color: var(--lightText);
}

@media (max-width: 768px) {
    .menu {
        display: none;
        position: absolute;
        background-color: teal;
        right: 0;
        left: 0;
        text-align: center;
        padding: 16px 0;
        z-index: 3;
    }

    .menu li+li {
        margin-top: 12px;
    }

    #checkbox_toggle:checked~.menu {
        display: block;
    }

    .hamburger {
        display: block;
    }

}

.img--logout {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
}

.navbar__logout {
    margin-left: auto;
    padding: 0 1rem 0 0;
    text-align: end;
}