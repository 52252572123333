.foundations-exercise-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.foundations-exercise-container h2 {
    margin-bottom: 20px;
    color: #333;
}

.foundations-filter-form {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: flex-end;
}

.filter-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
}

.filter-group label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}

.filter-group input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.filter-actions {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

.filter-button, .reset-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s;
}

.filter-button {
    background-color: #4a6cf7;
    color: white;
}

.filter-button:hover {
    background-color: #3a5ce5;
}

.reset-button {
    background-color: #e0e0e0;
    color: #333;
}

.reset-button:hover {
    background-color: #d0d0d0;
}

.loading, .no-results {
    text-align: center;
    padding: 30px;
    font-size: 16px;
    color: #666;
}

.exercises-table-container {
    overflow-x: auto;
}

.exercises-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.exercises-table th,
.exercises-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.exercises-table th {
    background-color: #f8f8f8;
    font-weight: 600;
    color: #333;
}

.exercises-table tr:hover {
    background-color: #f5f5f5;
}

.exercises-table tr.complete {
    background-color: rgba(76, 175, 80, 0.1);
}

.exercises-table tr.incomplete {
    background-color: rgba(255, 152, 0, 0.05);
}

@media (max-width: 768px) {
    .filter-controls {
        flex-direction: column;
    }

    .filter-group {
        width: 100%;
    }

    .filter-actions {
        margin-top: 10px;
    }
}